#about-me {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 90%;
  max-width: 2000px;
  margin-top: 250px;
  margin-bottom: 600px;
}

strong {
  color: aliceblue;
}

/* General Left Items */
#aboutme-left {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 40%;
  height: 100%;
}

.left h1 {
  font-size: 36px;
}

p {
  font-size: x-large;
}

/* General Right Items */
#aboutme-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
}

#aboutme-right img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 2px;
  border-radius: 160px;
  border-color: #4c5364;
  width: 150px;
  height: 150px;
  margin: 20px;
}

#aboutme-top {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

#aboutme-top h1 {
  font-size: 36px;
  padding-bottom: 100px;
}

#about-me #aboutme-right p {
  color: #d7dff8;
  text-align: left;
}

.paragraph {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#contact-button {
  background-color: rgb(57, 65, 94);
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: larger;
  border-style: solid;
  border-radius: 5px;
  border-width: 2px;
  padding: 4px;
  border-color: rgb(186, 197, 207);
  transition-duration: 0.2s;
  cursor: pointer;
  margin-top: 15px;
}

#contact-button:hover {
  border-color: aliceblue;
  color: rgb(255, 255, 255);
  background-color: rgb(35, 42, 66);
}

#skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 100%;
}

#skills-top h1 {
  font-size: 36px;
  padding-bottom: 100px;
}

#skills-top {
  color: aliceblue;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#skills-bottom {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
}

#skills-bottom img {
  width: 60px;
  padding-left: 20px;
  padding-right: 20px;
}

#skills-bottom img:hover + div {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 120px;
  padding: 10px;
  text-align: right;
  font-size: 24px;
  left: -105px;
  border-radius: 10px;
  opacity: 1;
  border-width: 0;
}

#skills-bottom h1 {
  font-size: 24px;
}

#skills-bottom li div {
  display: none;
  position: absolute;
  opacity: 0;
}

.skills-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skills-list li {
  color: aliceblue;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  font-size: 24px;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  text-align: center;
  height: 40px;
}

.tooltip {
  background-color: rgb(58, 58, 58);
}

@media screen and (max-width: 1800px) {
  h1 {
    color: purple;
  }
}

@media screen and (max-width: 992px) {
  #about-me {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100%;
    max-width: 1500px;
    margin-top: 80px;
    margin-bottom: 100px;
  }

  #skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 80px;
  }

  #skills-bottom {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
  }

  h1 {
    color: green;
  }
}

@media screen and (max-width: 768px) {

  #languages {
    display: none;
  }

  #aboutme-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 90%;
    max-width: 1500px;
    margin-top: 80px;
  }

  #aboutme-top h1 {
    font-size: 36px;
    padding-bottom: 10px;
  }

  #skills-top h1 {
    padding-bottom: 10px;
  }

  .paragraph {
    flex-direction: column-reverse;
  }

  .right {
    align-items: center;
    justify-content: center;
  }
  .left {
    display: flex;
    width: 95%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1%;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    color: blue;
  }
}

@media screen and (max-height: 600px) {
  #about-me {
    margin-bottom: 0px;
    margin-top: 30px;
  }

  #aboutme-top h1 {
    font-size: 36px;
    padding-bottom: 20px;
  }
}
