* {
  padding: 0;
  margin: 0;
}
html {
  scroll-behavior: smooth;
}

html,
body {
  overflow-x: hidden;
}
body {
  position: relative;
}

:root {
  --AltTextColor: rgb(235, 235, 235);
  --HeaderBackground: rgb(235, 235, 235);
  --TextColor: rgb(0, 0, 0);
  --MainBackground: rgb(235, 235, 235);
  --MainAccent: rgb(49, 49, 49);
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-image: linear-gradient(to bottom, #5f6374, #13161e);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d7dff8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(163, 168, 173);
}

body {
  background-image: linear-gradient(to bottom, #5f6374, #13161e);
  font-family: Helvetica, sans-serif;
}

h1 {
  color: aliceblue;
  font-family: Helvetica, sans-serif;
}

#resmue-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 95vh;
  margin-top: 40px;
}

#resume h1 {
  font-size: 35px;
}

.resume-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin-top: 20px;
}

/*   --desktop: 1400px;
  --laptop: 992px;
  --tablet: 768px;
  --phone: 480px;
*/

/* @media screen and (max-width: 1800px) {
  h1 {
  }
} */

/* @media screen and (max-width: 992px) {
  h1 {
  }
} */

/* @media screen and (max-width: 480px) {
} */
