.large-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 200px;
}

#work {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 2000px;
  align-items: center;
  justify-content: center;
}

.projects-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-height: 4000px;
  align-items: center;
  justify-content: center;
}

.projects-main a {
  padding-top: 20px;
}

#project-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#project-title h1 {
  font-size: 36px;
  padding-bottom: 60px;
}

#work-section {
  height: 10px;
  width: 100%;
  margin-bottom: 100px;
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  #project-title h1 {
    font-size: 36px;
    padding-bottom: 10px;
  }

  #work-section {
    height: 10px;
    width: 100%;
    margin-bottom: 0px;
  }

  .large-container {
    margin-bottom: 150px;
  }
}
