/* Styling for image located under the header */

.banner {
  display: flex;
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.animation-main {
  background-color: aliceblue;
  opacity: 0;
  transition: 4s;
}

.banner h3 {
  color: aliceblue;
  font-size: xx-large;
}

#text-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 200px;
  width: 30%;
}

.banner strong {
  font-size: 45px;
  color: #13161e;
  text-shadow: none;
  margin-bottom: 40px;
  text-align: center;
}

.banner h1 {
  font-size: xx-large;
  color: aliceblue;
  margin-bottom: 21px;
  text-align: center;
}

#carousel-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 55%;
}

#text-1 {
  display: flex;
  color: aliceblue;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  margin-bottom: 28px;
}

.text-mid {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  color: aliceblue;
}

.text-bottom {
  display: flex;
  color: aliceblue;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
}

#down-arrow {
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-size: 55px;
  margin-top: 20%;
  animation-name: floating;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-top: 40%;
}

.rec.rec-carousel-wrapper {
  height: 65px;
  width: 100%;
}

.rec.rec-carousel h3 {
  width: 100%;
}

.rec.rec-arrow {
  display: none;
}

.rec.rec-dot {
  display: none;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

@media screen and (max-width: 100000px) {
  .banner {
    padding-top: 120px;
  }
}

@media screen and (max-width: 2302px) {
  #text-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 200px;
    width: 40%;
  }
}

@media screen and (max-width: 1700px) {
  .banner {
    display: flex;
    background-size: auto 100vh;
  }

  #text-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 200px;
    width: 50%;
  }
}

@media screen and (max-width: 992px) {
  .banner {
    display: flex;
    background-size: auto 100vh;
  }

  .banner h1 {
    font-size: 55px;
  }

  #carousel-holder {
    width: 100%;
  }

  #text-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .theOthers {
    display: none;
  }

  .banner {
    padding-top: 0px;
  }
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 300px) {
  #text-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    width: 80%;
  }

  .banner {
    padding-top: 20px;
  }
}

@media screen and (max-height: 400px) {
  .banner {
    padding-top: 40px;
  }

  .banner h3 {
    font-size: x-large;
  }

  .banner h1 {
    font-size: 30px;
  }
  #text-1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    padding-bottom: 10px;
  }

  .text-mid {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
  }

  .text-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  #down-arrow {
    display: none;
  }
}
