.large-cell {
  display: flex;
  z-index: 10;
  position: absolute;
  flex-direction: column;
  width: 95%;
  margin-top: -200px;
  border-radius: 20px;
  padding-bottom: 1%;
  background-color: #5f6374;
  transition: 0.8s;
}

.display-off {
  display: flex;
  z-index: -1;
  opacity: 0;
  position: absolute;
  flex-direction: column;
  width: 95%;
  margin-top: 80px;
  border-radius: 20px;
  padding-bottom: 1%;
  background-color: #5f6374;
  transition: 0.8s;
}

.mobile-jump {
  display: flex;
  position: absolute;
  top: -200px;
}

#modal-button {
  position: absolute;
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  width: 50px;
  height: 50px;
  top: 0px;
  right: 0px;
  background-color: #d7dff8;
  border-color: #d7dff8;
  border-style: solid;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
  transition: 0.2s;
}

#modal-button h1{
  color: black;
}

#modal-button:hover {
  background-color: aliceblue;
  border-color: aliceblue;
  cursor: pointer;
}

#modal-button:active {
  background-color: #d7dff8;
  border-color: aliceblue;
}

.close-large-modal {
  opacity: 0;
  z-index: -1;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

#main-cell {
  display: flex;
  flex-direction: row;
}

#image-cell {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 800px;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgb(49, 49, 49);
  margin: 40px 20px 20px 20px;
}

b {
  color: aliceblue;
}

#right-cell {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 800px;
  margin: 40px 20px 20px 20px;
}

#title-cell {
  color: aliceblue;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: larger;
  padding-top: 1%;
}

#info-cell {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 700px;
}

#about-cell {
  color: #d7dff8;
  display: flex;
  flex-direction: column;
  padding-bottom: 10%;
  width: 50%;
  align-items: center;
  overflow-y: auto;
}

#dependancy-cell {
  color: aliceblue;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5%;
}

#dependancy-cell ul {
  font-size: larger;
}

#dependancy-cell li {
  color: #d7dff8;
  font-size: larger;
  padding: 20px 0px 0px 10px;
}

#link-cell {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 3%;
  height: 80px;
}

#link-cell button {
  width: 20%;
  height: 90%;
}

#github {
  background-color: rgb(57, 65, 94);
  color: rgb(255, 255, 255);
  font-size: larger;
  border-style: solid;
  border-radius: 5px;
  border-width: 2px;
  border-color: rgb(186, 197, 207);
  transition-duration: 0.2s;
  cursor: pointer;
}

#liveApp {
  background-color: rgb(57, 65, 94);
  color: rgb(255, 255, 255);
  font-size: larger;
  border-style: solid;
  border-radius: 5px;
  border-width: 2px;
  border-color: rgb(186, 197, 207);
  transition-duration: 0.2s;
  cursor: pointer;
}

#videoDemo {
  background-color: rgb(57, 65, 94);
  color: rgb(255, 255, 255);
  font-size: larger;
  border-style: solid;
  border-radius: 5px;
  border-width: 2px;
  border-color: rgb(186, 197, 207);
  transition-duration: 0.2s;
  cursor: pointer;
}

#github:hover {
  border-color: aliceblue;
  color: orange;
  background-color: rgb(35, 42, 66);
}

#liveApp:hover {
  border-color: aliceblue;
  color: rgb(247, 0, 193);
  background-color: rgb(35, 42, 66);
}

#videoDemo:hover {
  border-color: aliceblue;
  color: rgb(0, 206, 0);
  background-color: rgb(35, 42, 66);
}

.hidden {
  visibility: hidden;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s;
  z-index: 0;
}

.visible {
  visibility: visible;
  opacity: 1;
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  transition: visibility 0s, opacity 0.5s;
  z-index: 0;
  top: 0px;
  left: 0px;
}

.video-holder {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
  height: 100%;
}

#close-player {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 5px;
  border-style: none;
  background-color: rgba(255, 255, 255, 0.4);
  font-size: x-large;
  transition: 0.2s;
}

#close-player:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

#close-project-button {
  display: none;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 40px;
  color: #5f6374;
  font-size: large;
  background-color: #d7dff8;
  border-color: #d7dff8;
  border-style: solid;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: 0.1s;
}

#close-project-button:active {
  color: #d7dff8;
  background-color: #5f6374;
  border-color: aliceblue;
}

@media screen and (max-width: 1800px) {
}

@media screen and (max-width: 992px) {
  .display-off {
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    transition: 0.4s;
  }
  .large-cell {
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    transition: 0.4s;
  }
  #main-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #right-cell {
    height: 900px;
  }
  #image-cell {
    width: 90%;
    height: 800px;
  }
  #info-cell {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    padding: 1%;
    max-height: 700px;
  }

  #dependancy-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  #link-cell {
    align-items: center;
  }

  #link-cell button {
    width: 20%;
    height: 60%;
  }
}

@media screen and (max-width: 768px) {
  .large-cell {
    align-items: center;
    justify-content: center;
    margin-top: 0px;
  }

  #right-cell {
    height: 100%;
    flex-direction: column-reverse;
    margin: 0;
  }
  #image-cell {
    width: 90%;
    height: 300px;
  }
  #info-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 1%;
    max-height: 2000px;
  }
  #about-cell {
    width: 90%;
  }

  #title-cell {
    padding-top: 5%;
    width: 50%;
    align-items: center;
  }

  #title-cell h2 {
    text-align: center;
  }

  #link-cell {
    padding: 0;
  }

  #link-cell button {
    width: 25%;
    height: 60%;
  }

  #dependancy-cell ul {
    list-style-type: none;
    width: 100%;
  }
  #dependancy-cell li {
    color: aliceblue;
    background-color: #5f6374;
    border-radius: 10px;
    border-style: solid;
    align-items: center;
    text-align: center;
    padding: 1%;
  }
  .large-cell {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  #close-project-button {
    display: flex;
  }
}
