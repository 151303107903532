.large-work {
  display: flex;
  position: relative;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px -50px;
  border-width: 5px;
  opacity: 0.8;
  border-style: solid;
  border-color: #4b5563;
  align-items: flex-start;
  width: 100%;
  height: 400px;
  margin: 1%;
  margin-bottom: 80px;
  transition: .3s;
}

.large-work:hover {
  opacity: 1;
  cursor: pointer;
}

.text-p {
  position: absolute;
  color: aliceblue;
  top: 0;
  left: -1px;
  font-size: 25px;
  background-color: #4b5563;
  padding: 10px;
  border-radius: 0 0px 10px 0;
}

.icon-holder{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: fit-content;
  padding: 10px;
  height: 100%;
  border-radius: 0 10px 0px 0;
  margin-bottom: 5px;
}

.icon-item{
  background-color: aliceblue;
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 2px solid #2f3a47;
  margin-left: 10px;
}

/* Small items inside of the Work Section */
.small-work {
  display: flex;
  position: relative;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.8;
  border-style: solid;
  border-width: 5px;
  border-color: #4b5563;
  justify-content: space-between;
  width: 47%;
  height: 200px;
  margin: 1%;
  margin-bottom: 80px;
  transition: .3s;
}

.small-work:hover {
  opacity: 1;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .large-work {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media screen and (max-width: 998px) {
  .small-work {
    width: 97%;
    margin-bottom: 20px;
    height: 200px;
  }

  .large-work {
    width: 97%;
    height: 450px;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media screen and (max-width: 768px) {
  .large-work {
    border-color: #4b5563;
    align-items: flex-start;
  }

  .left-and-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 99%;
  }
  .left {
    flex-direction: row;
  }
  .work-right {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .small-work {
    opacity: 1;
  }

  .large-work {
    opacity: 1;
  }

  .large-container {
    padding-bottom: 20px;
  }

  .icon-item{
    margin-left: 3px;
  }
}

@media screen and (max-width: 768px) {
  .icon-item{
    height: 35px;
    width: 35px;
    padding: 3px;
  }

  .icon-holder{
    margin-left: 3px;
    padding: 0px;
    margin-bottom: 2px;
  }
}