/* Change the color of the links when clicked */
header a:hover,
header a:visited,
header a:link,
header a:active {
  color: aliceblue;
  text-decoration: none;
  text-shadow: black;
}

.menu-button {
  display: none;
  width: 50%;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 20px;
}

.header-nav {
  width: 100%;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  padding-right: 1%;
}

.header-top {
  background-color: rgb(49, 49, 49, 0.5);
}

.header-nav ul {
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.header-nav ul li {
  display: flex;
  margin-left: 25px;
  margin-right: 25px;
}

header section {
  display: flex;
  padding: 10px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgb(49, 49, 49);
  justify-content: flex-end;
  color: var(--TextColor);
  justify-content: space-between;
}

.default {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 0%;
  transition: 0.2s;
}

.modified {
  display: flex;
  z-index: 1;
  position: absolute;
  background-color: rgb(49, 49, 49, 0.9);
  padding-bottom: 2%;
  transition: 0.2s;
}

.close-modal {
  position: fixed;
  opacity: 0;
  background-color: antiquewhite;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

header {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
}

/* Profile Picture along with name */
header div {
  display: flex;
  align-items: center;
  color: var(--TextColor);
}

.header-bottom {
  background-color: rgb(49, 49, 49);
  height: 10px;
  width: 100%;
  padding-left: 0;
}

header div h2 {
  font-size: 30px;
  color: var(--TextColor);
}

header h1 {
  display: flex;
  font-size: 48px;
  text-decoration: none;
}

@media screen and (max-width: 1800px) {
  h1 {
    color: purple;
  }
  .default {
    display: none;
    position: fixed;
  }

  .modified {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  h1 {
    color: green;
  }
  header section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  header nav {
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    padding-top: 1%;
  }

  .default {
    display: none;
    position: fixed;
  }

  .modified {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  h1 {
    color: red;
  }

  .menu-button {
    display: flex;
    flex-direction: column;
    position: fixed;
    align-items: center;
    height: 70px;
    width: 70px;
    top: 0px;
    left: 0px;
    z-index: 3;
    background-color: rgb(49, 49, 49, 0.9);
    color: aliceblue;
    border-color: rgb(109, 109, 109);
    border-style: none;
    border-radius: 0px 0px 20px 00px;
    outline: none;
  }

  .menu-button div {
    width: 35px;
    height: 5px;
    background-color: rgb(240, 248, 255, 0.9);
    margin: 4px 0;
    border-radius: 2px;
  }

  /* .default {
    top: 0;
    left: 0;
    width: 0%;
    transition: 5s;
  } */

  .default {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 70%;
    top: 0px;
    left: -600px;
    transition: 0.3s;
  }

  .modified {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 70%;
    top: 0px;
    left: 0px;
    transition: 0.3s;
  }

  #nav-list {
    justify-content: flex-start;
    align-items: center;
    height: 50%;
    margin-top: 10%;
  }

  #nav-list li {
    margin-top: 10%;
  }

  #nav-image-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 80%;
    align-items: center;
    justify-content: space-evenly;
  }

  #nav-image-list a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
  }

  .nav-images {
    height: 60px;
    width: 60px;
  }

  header section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  header {
    position: relative;
    width: 100%;
    top: 1;
  }

  .header-top {
    background-color: #5f6374;
    text-shadow: #5f6374;
  }

  header div {
    flex-direction: column;
    margin-left: 0px;
  }

  header div h2 {
    font-size: 40px;
    color: var(--TextColor);
    padding-left: 0px;
    padding-bottom: 20px;
  }

  header div img {
    height: 120px;
    width: 120px;
    border-radius: 100px;
    margin-right: 0px;
    margin-bottom: 10px;
  }

  header nav {
    display: none;
    align-items: center;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 30px;
    padding-top: 1%;
    width: 80%;
  }

  header nav ul {
    flex-direction: column;
    width: 100%;
  }

  header nav ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2%;
    padding-bottom: 2%;
    border-radius: 10px;
    border-style: none;
    margin-top: 1%;
    width: 100%;
  }

  header a:hover,
  header a:visited,
  header a:link,
  header a:active {
    color: aliceblue;
    text-decoration: none;
  }

  .header-nav {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .default {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 70%;
    top: 0px;
    left: -300px;
    transition: 0.3s;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    color: blue;
  }
}
