/* Footer elements, Contact me elements */
footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 0;
  border-style: none;
  background-color: #13161e;
  padding: 1%;
  padding-bottom: 50px;
  margin-top: 20px;
}

/* Footer Top */
#footer-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin: 10px;
}

#contact-text h1 {
  color: aliceblue;
  font-size: xx-large;
}

/* Footer Bottom */
#footer-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

#contact-form-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  border-radius: 5px;
  padding: 3%;
}

#contact-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 20px;
  width: 50%;
  color: aliceblue;
}

#contact-details ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 1%;
  width: 100%;
}

footer a {
  color: aliceblue;
}

footer p {
  color: aliceblue;
}

#contact-form-holder {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1800px) {
  h1 {
    color: purple;
  }
  #contact-details ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #contact-details ul li {
    padding-top: 1%;
    padding-bottom: 2%;
    margin-left: 0%;
  }
}

@media screen and (max-width: 992px) {
  h1 {
    color: green;
  }
}

@media screen and (max-width: 768px) {
  h1 {
    color: red;
  }
  #footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  #contact-form-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    border-radius: 5px;
    padding: 3%;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    color: blue;
  }
}
